<script setup lang="ts">
import type { SourceRaw, Source } from '~/src/classes.ts';

const props = defineProps<{
    sources: (SourceRaw | Source)[] | null;
    label: string;
}>();

const publishDates = computed(() => {
    if (props.sources === null) {
        return null;
    }
    const dates: Record<number, number> = {};
    let count = 0;
    let min, max;
    for (const source of props.sources) {
        if (source.year) {
            if (dates[source.year] === undefined) {
                dates[source.year] = 0;
            }
            dates[source.year]++;
            count++;
            if (min === undefined || source.year < min) {
                min = source.year;
            }
            if (max === undefined || source.year > max) {
                max = source.year;
            }
        }
    }
    if (Object.keys(dates).length < 2 || count < 5 || min === undefined || max === undefined) {
        return null;
    }
    for (let i = min + 1; i < max; i++) {
        if (dates[i] === undefined) {
            dates[i] = 0;
        }
    }
    return dates;
});
</script>

<template>
    <details v-if="publishDates !== null && $isGranted('sources')" class="border">
        <summary class="bg-light px-3 py-2">
            <Icon v="chart-line" />
            Chart
        </summary>
        <section class="m-0">
            <div class="card">
                <div class="card-header small">
                    <Icon v="chart-line" />
                    This chart is only visible for the admins.
                    Please take it with a grain of salt, considering that our list of sources is very incomplete.
                </div>
                <div class="card-body">
                    <Chart :data="publishDates" :label="label" />
                </div>
            </div>
        </section>
    </details>
</template>
