<script setup lang="ts">
import type { Source, Filter, Pronoun } from '~/src/classes.ts';
import { makeId } from '~/src/helpers.ts';

const props = defineProps<{
    sources: Source[];
    pronoun?: Pronoun;
    filter?: Filter;
}>();

const sourcesUnique = computed(() => {
    const sourcesMap: Record<string, Source> = {};
    for (const source of props.sources) {
        sourcesMap[source.id] = source;
    }
    return Object.values(sourcesMap);
});

const visibleSources = computed(() => {
    return sourcesUnique.value.filter((source) => !props.filter || source.matches(props.filter));
});

const notEmpty = computed(() => {
    return visibleSources.value.length > 0;
});

const listId = makeId(6);
</script>

<template>
    <div v-if="notEmpty">
        <slot></slot>
        <div v-if="pronoun && pronoun.sourcesInfo" class="alert alert-info small">
            <Icon v="info-circle" />
            <LinkedText :text="pronoun.sourcesInfo" />
        </div>
        <SourcesChart :sources="sources" :label="pronoun ? pronoun.name() : ''" />
        <ul class="list-unstyled">
            <li v-for="source in visibleSources" :key="`${source.id}-${listId}`" class="my-2 clearfix">
                <SourceItem :source="source" />
            </li>
        </ul>
    </div>
</template>
